<template>
  <div class="homeBox">
    <div class="homeBanner">
      <div class="slogan">
        <span>“豸”</span>为公平正义 <span>“信”</span>因明辨真伪
      </div>
      <div class="label">
        豸信CA，国内领先的电子认证服务和信息安全解决方案提供商
      </div>
      <div class="introduce">
        <div class="clearfix">
          <img src="@/assets/image/home/introduce1.png" alt="豸信CA是什么" />
          <div>
            <p class="tt">豸信CA是什么？</p>
            <p>提供基于云计算的安全技术研发、<br />集成以及信息安全服务</p>
          </div>
        </div>
        <div class="clearfix">
          <img src="@/assets/image/home/introduce2.png" alt="选择豸信CA" />
          <div>
            <p class="tt">为什么选择豸信CA？</p>
            <p>
              拥有覆盖全国的电子认证服务网络<br />和较完善的电子认证产品体系
            </p>
          </div>
        </div>
        <div class="clearfix">
          <img src="@/assets/image/home/introduce3.png" alt="应用领域" />
          <div>
            <p class="tt">我们的应用领域？</p>
            <p>覆盖政务、医疗、教育、企业等多<br />个领域电子认证服务</p>
          </div>
        </div>
        <div class="clearfix">
          <img src="@/assets/image/home/introduce4.png" alt="专业可信" />
          <div>
            <p class="tt">专业可信的电子签署服务</p>
            <p>一站式解决电子签署难题，合规合<br />法安全高效</p>
          </div>
        </div>
      </div>
    </div>
    <div class="productIntroduction">
      <h2>产品介绍</h2>
      <h5>根据您不同场景的业务需求提供相应的产品</h5>
      <div class="products">
        <div class="productsBtns">
          <div>
            <div
              v-for="(item, index) in product"
              :key="index"
              @mouseenter="productActive = index"
              :class="{ active: index == productActive }"
            >
              <img
                :src="
                  require('@/assets/image/home/product-icon' +
                    (index + 1) +
                    '.png')
                "
                :alt="item.name"
              />
              <h3>{{ item.name }}</h3>
            </div>
          </div>
        </div>
        <div class="productsTabs">
          <div>
            <div class="productsText">
              <div>{{ product[productActive].intro }}</div>
            </div>
            <div class="exampleBox">
              <div
                v-for="(item, index) in product[productActive].examples"
                :key="index"
                class="productsExample"
              >
                <div class="tt">{{ item.name }}</div>
                <div class="explain">{{ item.intro }}</div>
                <el-button
                  @click="$router.push({ name: item.path })"
                  type="primary"
                  size="small"
                  >查看详情</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="partnership">
      <h2>我们的客户与合作伙伴</h2>
      <div class="clearfix">
        <div class="customer" v-for="item in 18" :index="item" :key="item">
          <img
            :src="require('@/assets/image/home/partners' + item + '.png')"
            alt=""
          />
        </div>
      </div>
    </div>
    <v-networkSecurity />
  </div>
</template>

<script>
import networkSecurity from '../../components/networkSecurity.vue'
export default {
  name: 'mobileHome',
  data() {
    return {
      product: [
        {
          name: '数字证书',
          intro:
            '豸信CA作为独立、公正、可信的CA机构，严格按照《中华人民共和国电子签名法》、《电子认证服务管理办法》等相关法律要求为各类用户颁发数字证书，并保障数字证书的安全使用。豸信CA可颁发机构证书、个人证书以及国密SSL证书等各类证书并广泛应用于政府、医院、高校、企业等各个领域。',
          examples: [
            {
              path: 'product-orgCert',
              name: '机构证书',
              intro: '用于标识证书持有机构在网络上的真实身份'
            },
            {
              path: 'product-personCert',
              name: '个人证书',
              intro: '用以标识个人在互联网上的数字身份'
            }
          ]
        },
        {
          name: '电子签章',
          intro:
            '电子签章利用图像处理技术将电子签名操作转化为与纸质文件盖章操作相同的可视效果，同时利用电子签名技术保障电子信息的真实性和完整性以及签名人的不可否认性。豸信CA提供多种电子签章产品适配不同客户的电子签章诉求。',
          examples: [
            {
              path: 'product-sealBox',
              name: '智能印章一体机',
              intro: '软硬件融合实现企业电子印章的智能化管理和安全性管理。'
            },
            {
              path: 'product-eSeal',
              name: '电子签章系统',
              intro: '将传统印章图片与可靠的电子签名技术完美结合'
            }
          ]
        },
        {
          name: '数据安全与密码服务',
          intro:
            '密码技术是保障网络空间安全的核心技术和基础支撑，是实现数据安全和网络安全的重要途径。豸信CA致力于使用国产密码技术，有效保证个人信息安全和企业商业机密在数据产生、传递、存储中的机密性、完整性以及不可否认性。',
          examples: [
            {
              path: 'product-verifySign',
              name: '签名验签',
              intro: '有效地保障业务数据的真实性和有效性'
            },
            {
              path: 'product-timestamp',
              name: '可信时间戳',
              intro: '证明电子数据的完整性及产生时间'
            }
          ]
        },
        {
          name: '移动端数字密码服务',
          intro:
            '手机盾是面向移动端场景的一款产品,提供可信的数字签名功能。协同签名系统采用密钥分割技术和协同数字签名技术,实现了密钥分量在移动签名组件和服务器端的独立生成和独立存储。签名过程中,移动端和服务端协作,共同完成完整签名。可适用于OA协同、电子政务、移动办公、金融支付、健康医疗、移动端身份认证等多种场景。',
          examples: [
            {
              path: 'product-mobileShield',
              name: '手机盾',
              intro: '移动端的数字密码应用服务'
            }
          ]
        }
      ],
      productActive: 0
    }
  },
  components: {
    'v-networkSecurity': networkSecurity
  }
}
</script>
<style lang="scss" scoped>
.homeBox {
  width: 7.5rem;
}
.homeBanner {
  width: 7.5rem;
  height: 8.18rem;
  background: url('~@/assets/image/home/banner.png') left center no-repeat;
  background-size: 100% 100%;
  margin-bottom: 8.85rem;
  position: relative;
  .slogan {
    padding-top: 1.08rem;
    font-size: 0.48rem;
    font-weight: bold;
    color: #1c3c58;
    line-height: 0.67rem;
    text-align: center;
    span {
      color: #0066ff;
    }
  }
  .label {
    padding: 0.42rem 1rem 0;
    font-size: 0.28rem;
    color: #303b50;
    line-height: 0.44rem;
    text-align: center;
  }
}

.introduce {
  width: 5.42rem;
  position: absolute;
  top: 7.97rem;
  left: 1.05rem;
  > div {
    width: 5.42rem;
    height: 1.8rem;
    padding: 0 0.56rem 0 0.42rem;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0.04rem 0.1rem 0px rgba(31, 37, 81, 0.11);
    border-radius: 0.04rem;
    margin-bottom: 0.32rem;
    display: flex;
    align-items: center;
  }
  img {
    width: 0.6rem;
    height: 0.6rem;
    margin-top: 0.24rem;
    margin-right: 0.24rem;
    float: left;
  }
  p {
    font-size: 0.24rem;
    font-weight: 400;
    color: #8d96a3;
    line-height: 0.32rem;
  }
  .tt {
    font-size: 0.28rem;
    font-weight: bold;
    color: #2d3b52;
    line-height: 0.4rem;
    margin-bottom: 0.12rem;
  }
}
.productIntroduction {
  width: 7.5rem;
  padding: 0 0.16rem;
  box-sizing: border-box;
  h2 {
    font-size: 0.48rem;
    color: #2d3b52;
    line-height: 0.67rem;
    margin-bottom: 0.24rem;
    text-align: center;
  }
  h5 {
    font-size: 0.24rem;
    font-weight: 400;
    color: #8b96a5;
    line-height: 0.33rem;
    text-align: center;
    margin-bottom: 0.42rem;
  }
}
.productsBtns {
  overflow-x: scroll;
  > div {
    width: max-content;
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 18px;
    color: #303b50;
    line-height: 24px;
    & > div {
      transition: all 0.2;
      cursor: pointer;
      margin-left: 0.4rem;
      &.active {
        h3 {
          color: #0059ff;
          position: relative;
          &::after {
            content: '';
            width: 100%;
            height: 2px;
            background: #0059ff;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
      &:hover h3 {
        color: #0059ff;
      }
    }
  }
  img {
    width: 0.8rem;
  }
  h3 {
    font-size: 0.28rem;
    font-weight: 400;
    color: #303b50;
    line-height: 0.4rem;
    padding-bottom: 0.16rem;
  }
}
.productsTabs {
  width: 100%;
  background: #f9fbff;
  > div {
    padding: 0.42rem;
  }
}
.productsText {
  font-size: 0.26rem;
  font-weight: 400;
  color: #293c54;
  line-height: 0.4rem;
}
.productsExample {
  width: 2.6rem;
  height: 3.22rem;
  background: linear-gradient(124deg, #eceef9 0%, #ffffff 100%);
  box-shadow: 0px 0.04rem 0.1rem 0px rgba(31, 37, 81, 0.11);
  border-radius: 0.04rem;
  border: 0.02rem solid #ffffff;
  margin-top: 0.32rem;
  padding: 0.42rem 0.3rem;
  box-sizing: border-box;
  position: relative;
  .tt {
    font-size: 0.28rem;
    font-weight: bold;
    color: #303b50;
    line-height: 0.4rem;
    margin-bottom: 0.08rem;
  }
  .explain {
    font-size: 0.24rem;
    font-weight: 400;
    color: #666e7a;
    line-height: 0.32rem;
  }
  .el-button {
    width: 1.32rem;
    position: absolute;
    bottom: 0.42rem;
    left: 0.64rem;
  }
}
.exampleBox {
  display: flex;
  padding: 0 0.37rem;
  justify-content: space-between;
}
.partnership {
  padding: 0.9rem 1.13rem 0.36rem;
  h2 {
    font-size: 0.48rem;
    font-weight: bold;
    color: #2d3b52;
    line-height: 0.67rem;
    text-align: center;
    margin-bottom: 0.64rem;
  }
}
.customer {
  width: 2.42rem;
  height: 1.44rem;
  margin: 0 0.4rem 0.4rem 0;
  float: left;
  background: #fdfdff;
  box-shadow: 0px 0.02rem 0.08rem 0px rgba(31, 37, 81, 0.11);
  border-radius: 0.04rem;
  &:nth-child(2n) {
    margin-right: 0;
  }
}
</style>
